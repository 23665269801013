import { Controller } from "@hotwired/stimulus";

let model = null;
let predict = false;
let videoLoaded = false;

const IMG_WIDTH = 224;
const IMG_HEIGHT = 224;

export default class extends Controller {
    static targets = ["addMoreDataButton", "deployButton", "reviewButton", "correctProgress", "correctProgressText", "defectiveProgress", "defectiveProgressText", "snap"];

    async connect() {
        predict = true;
        let videoElement = document.getElementById('video');
        videoElement.addEventListener('loadeddata', async () => {
            videoLoaded = true;
            await this.loadModel();
            this.startPredicting();
        });
    }

    async loadModel() {
        const model_path = this.element.getAttribute('data-model_path');
        console.log('model_path:', model_path);
        model = await tflite.loadTFLiteModel(model_path);
        console.log('Trained model loaded successfully!');
    }

    startPredicting() {
        predict = true;
        this.updateInterval = setInterval(() => {
            this.performPredictionAndUpdateBars();
        }, 300);
    }

    performPredictionAndUpdateBars() {
        if (!predict || !videoLoaded) {
            clearInterval(this.updateInterval);
            return;
        }

        tf.tidy(() => {
            let canvas = document.getElementById('canvas');
            let videoFrameAsTensor = tf.browser.fromPixels(canvas).div(255);
            let resizedTensorFrame = tf.image.resizeBilinear(videoFrameAsTensor, [IMG_HEIGHT, IMG_WIDTH], true);
            let prediction = model.predict(resizedTensorFrame.expandDims()).squeeze();
            let highestIndex = prediction.argMax().arraySync();
            let predictionArray = prediction.arraySync();
            let score = Math.round(predictionArray[highestIndex] * 100);
            this.updateBars(score, highestIndex);
        });
    }

    updateBars(score, highestIndex) {
        let defectiveWidth = highestIndex === 1 ? score : 100 - score;
        let correctWidth = 100 - defectiveWidth;

        this.defectiveProgressTarget.style.width = `${defectiveWidth}%`;
        this.correctProgressTarget.style.width = `${correctWidth}%`;

        if (defectiveWidth === 100) {
            this.defectiveProgressTextTarget.textContent = `${defectiveWidth}%`;
            this.correctProgressTextTarget.textContent = '';
        } else if (correctWidth === 100) {
            this.correctProgressTextTarget.textContent = `${correctWidth}%`;
            this.defectiveProgressTextTarget.textContent = '';
        } else {
            this.defectiveProgressTextTarget.textContent = `${defectiveWidth}%`;
            this.correctProgressTextTarget.textContent = `${correctWidth}%`;
        }
    }

    stopPredicting() {
        predict = false;
        if (this.updateInterval) {
            clearInterval(this.updateInterval);
        }
    }

    toggleButtons() {
        this.addMoreDataButtonTarget.style.display = 'none';
        this.deployButtonTarget.style.display = 'none';
        this.reviewButtonTarget.style.display = 'block';
        this.snapTarget.style.display = 'flex';
    }

    submitForm(event) {
        event.preventDefault();
        const klassValue = event.currentTarget.dataset.klass; // Get the class value from the button

        const klassField = this.element.querySelector("#sample_klass");
        if (klassField) {
            klassField.value = klassValue;
        } else {
            console.error("Klass field not found.");
            return;
        }

        const form = this.element.querySelector("#new-sample-form");
        if (form) {
            form.submit();
        } else {
            console.error("Form not found.");
        }
    }

    disconnect() {
        this.stopPredicting();
    }
}
