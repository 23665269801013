import { Controller } from "@hotwired/stimulus"
import tippy, { followCursor } from 'tippy.js';

export default class extends Controller {
  static values = {
    content: String,
    interactive: Boolean,
    followCursor: Boolean
  }

  connect() {
    let options = {
      interactive: this.interactiveValue || false,
      theme: 'blue'
    };

    if (this.followCursorValue) {
      options['followCursor'] = true;
      options['plugins'] = [followCursor];
    }

    if (this.hasContentValue) {
      options['content'] = this.contentValue;
    }

    this.tippy = tippy(this.element, options);
  }

  disconnect() {
    if (this.tippy) {
      this.tippy.destroy();
    }
  }
}