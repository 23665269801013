import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["video", "canvas", "cameraError", "videoContainer", "snap", "formButtonContainer", "sampleImage"];
    renderCanvas = true; // Flag to control rendering

    connect() {
        this.canvasContext = this.canvasTarget.getContext('2d');

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 } })
                .then(stream => {
                    this.videoTarget.srcObject = stream;
                    this.videoTarget.onloadedmetadata = () => {

                        // If user media was previously off
                        this.videoContainerTarget.style.display = 'flex'; // Show the video container
                        this.cameraErrorTarget.style.display = 'none'; // Hide error container
                        this.snapTarget.disabled = false;

                        // Dynamically set dimensions
                        const dimension = Math.min(this.videoTarget.videoWidth, this.videoTarget.videoHeight, 640);
                        this.canvasTarget.width = dimension;
                        this.canvasTarget.height = dimension;
                        this.videoTarget.width = dimension;
                        this.videoTarget.height = dimension;

                        // Stream video
                        this.videoTarget.play();
                        this.renderToCanvas();
                    };
                })
                .catch(err => {
                    this.videoContainerTarget.style.display = 'none'; // Hide the video container
                    this.cameraErrorTarget.style.display = 'flex'; // Show error container
                    this.snapTarget.disabled = true; // Disable snap button
                });
        } else {
            console.error("getUserMedia not supported in this browser.");
            this.videoContainerTarget.style.display = 'none'; // Hide the video container
            this.cameraErrorTarget.style.display = 'flex'; // Show error container
            this.snapTarget.disabled = true;
        }
    }

    renderToCanvas() {
        if (this.renderCanvas) {
            const videoWidth = this.videoTarget.videoWidth;
            const videoHeight = this.videoTarget.videoHeight;
            const startX = (videoWidth - this.canvasTarget.width) / 2;
            const startY = (videoHeight - this.canvasTarget.height) / 2;

            this.canvasContext.drawImage(this.videoTarget, startX, startY, this.canvasTarget.width, this.canvasTarget.height, 0, 0, this.canvasTarget.width, this.canvasTarget.height);
            requestAnimationFrame(() => this.renderToCanvas());
        }
    }

    async photo(event) {
        this.renderCanvas = false; // Disable live rendering

        // Capture a snapshot from the video
        const videoWidth = this.videoTarget.videoWidth;
        const videoHeight = this.videoTarget.videoHeight;
        const startX = (videoWidth - this.canvasTarget.width) / 2;
        const startY = (videoHeight - this.canvasTarget.height) / 2;

        const imageCanvas = document.createElement('canvas');
        imageCanvas.width = this.canvasTarget.width;
        imageCanvas.height = this.canvasTarget.height;
        const imageContext = imageCanvas.getContext('2d');
        imageContext.drawImage(this.videoTarget, startX, startY, this.canvasTarget.width, this.canvasTarget.height, 0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Clear the current canvas
        this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Display the captured image on the current canvas
        this.canvasContext.drawImage(imageCanvas, 0, 0);
        this.snapTarget.blur();

        // Retrieve data passed via data-* attributes
        const arg = event.currentTarget.dataset.arg;

        if(arg === 'collect-data' || arg === 'evaluate-object-detector') {
            this.snapTarget.style.display = 'none';
            this.formButtonContainerTarget.style.display = 'flex';
        }

        if(arg === 'collect-data' || arg === 'evaluate-classifier') {
            // Set the image hidden input
            this.sampleImageTarget.value = this.canvasTarget.toDataURL('image/jpeg')
        }

        imageCanvas.remove();
    }

    startStreaming() {
        this.renderCanvas = true; // Enable live rendering
        this.renderToCanvas();
        this.formButtonContainerTarget.style.display = 'none';
        this.snapTarget.style.display = 'flex';
    }

    resetCameraAndButtons() {
        this.startStreaming();
    }

    switchTab() {
        if (this.renderCanvas === false) { // Check if an image has been captured
            this.resetCameraAndButtons(); // Resets and starts streaming again
        }
    }

    startTraining() {
        document.getElementById('loading-screen').style.display = 'flex';
    }

    cancel(event) {
        event.preventDefault()
        this.startStreaming();
    }

    disconnect() {
        this.renderCanvas = false;

        if (this.videoTarget.srcObject) {
            this.videoTarget.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }

        this.videoTarget.srcObject = null;
        this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

    }
}
