import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    intervalTimer = null;
    static targets = ["video", "canvas", "cameraError", "videoContainer", "snap", "record", "formButtonContainer", "recordButtonContainer", "counter", "sampleImage"];
    renderCanvas = true; // Flag to control rendering

    connect() {
        console.log("w, h", this.videoTarget.videoWidth, this.videoTarget.videoHeight);
        this.canvasContext = this.canvasTarget.getContext('2d');

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 } })
                .then(stream => {
                    this.videoTarget.srcObject = stream;
                    this.videoTarget.onloadedmetadata = () => {

                        // If user media was previously off
                        this.videoContainerTarget.style.display = 'flex'; // Show the video container
                        this.cameraErrorTarget.style.display = 'none'; // Hide error container
                        this.snapTarget.disabled = false;

                        // Stream video
                        this.videoTarget.play();
                        this.renderToCanvas();
                    };
                })
                .catch(err => {
                    this.videoContainerTarget.style.display = 'none'; // Hide the video container
                    this.cameraErrorTarget.style.display = 'flex'; // Show error container
                    this.snapTarget.disabled = true; // Disable snap button
                });
        } else {
            console.error("getUserMedia not supported in this browser.");
            this.videoContainerTarget.style.display = 'none'; // Hide the video container
            this.cameraErrorTarget.style.display = 'flex'; // Show error container
            this.snapTarget.disabled = true;
        }
    }

    renderToCanvas() {
        console.log("video w, video h", this.videoTarget.videoWidth, this.videoTarget.videoHeight);

        if (this.renderCanvas) {
            const videoWidth = this.videoTarget.videoWidth;
            const videoHeight = this.videoTarget.videoHeight;

            // Calculate the starting point to crop the middle 640x640 section
            const cropSize = 640;
            const startX = (videoWidth - cropSize) / 2;
            const startY = (videoHeight - cropSize) / 2;

            // Draw the cropped section on the canvas
            // The canvas will scale it down to fit its size (480x480)
            this.canvasContext.drawImage(this.videoTarget, startX, startY, cropSize, cropSize, 0, 0, this.canvasTarget.width, this.canvasTarget.height);
            requestAnimationFrame(() => this.renderToCanvas());
        }
    }

    async photo(event) {
        this.renderCanvas = false; // Disable live rendering

        // Capture a snapshot from the video
        const videoWidth = this.videoTarget.videoWidth;
        const videoHeight = this.videoTarget.videoHeight;
        const startX = (videoWidth - this.canvasTarget.width) / 2;
        const startY = (videoHeight - this.canvasTarget.height) / 2;

        const imageCanvas = document.createElement('canvas');
        imageCanvas.width = this.canvasTarget.width;
        imageCanvas.height = this.canvasTarget.height;
        const imageContext = imageCanvas.getContext('2d');
        imageContext.drawImage(this.videoTarget, startX, startY, this.canvasTarget.width, this.canvasTarget.height, 0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Clear the current canvas
        this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Display the captured image on the current canvas
        this.canvasContext.drawImage(imageCanvas, 0, 0);
        this.snapTarget.blur();

        // Retrieve data passed via data-* attributes
        const arg = event.currentTarget.dataset.arg;

        if(arg === 'collect-data') {
            this.snapTarget.style.display = 'none';
            this.formButtonContainerTarget.style.display = 'flex';
        }

        if(arg === 'collect-data' || arg === 'evaluate-classifier') {
            // Set the image hidden input
            this.sampleImageTarget.value = this.canvasTarget.toDataURL('image/jpeg')
        }

        if(this.recordTarget) {
            this.recordTarget.style.display = 'none';
        }

        imageCanvas.remove();
    }

    record(event) {
        event.preventDefault();
        this.startCounter();
        this.intervalTimer = setInterval(() => {
            this.captureSampleForRecording();
        }, 2000);

        // Hide record button and show cancel button
        this.recordTarget.style.display = 'none';
        this.snapTarget.style.display = 'none';
        this.recordButtonContainerTarget.style.display = 'flex';
    }

    startCounter() {
        this.counterValue = 0;

        this.counterInterval = setInterval(() => {
            this.counterValue++;
            this.updateCounterDisplay();
        }, 1000);

        this.counterTarget.classList.remove('hidden');
    }

    updateCounterDisplay() {
        const minutes = Math.floor(this.counterValue / 60).toString().padStart(2, '0');
        const seconds = (this.counterValue % 60).toString().padStart(2, '0');
        this.counterTarget.textContent = `${minutes}:${seconds}`;
    }


    captureSampleForRecording() {
        const videoWidth = this.videoTarget.videoWidth;
        const videoHeight = this.videoTarget.videoHeight;
        const startX = (videoWidth - this.canvasTarget.width) / 2;
        const startY = (videoHeight - this.canvasTarget.height) / 2;

        const imageCanvas = document.createElement('canvas');
        imageCanvas.width = this.canvasTarget.width;
        imageCanvas.height = this.canvasTarget.height;
        const imageContext = imageCanvas.getContext('2d');
        imageContext.drawImage(this.videoTarget, startX, startY, this.canvasTarget.width, this.canvasTarget.height, 0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Set the image hidden input
        this.sampleImageTarget.value = imageCanvas.toDataURL('image/jpeg');

        // Additional processing if necessary...
        imageCanvas.remove();
        document.getElementById('submit-form').click();
    }


    startStreaming() {
        this.renderCanvas = true; // Enable live rendering
        this.renderToCanvas();
        this.formButtonContainerTarget.style.display = 'none';
        this.recordTarget.style.display = 'flex';
        this.snapTarget.style.display = 'flex';
    }

    resetCameraAndButtons() {
        this.startStreaming();
    }

    switchTab() {
        if (this.renderCanvas === false) { // Check if an image has been captured
            this.resetCameraAndButtons(); // Resets and starts streaming again
        }
    }

    startTraining() {
        document.getElementById('loading-screen').style.display = 'flex';
    }

    cancel(event) {
        this.recordButtonContainerTarget.style.display = 'none';
        event.preventDefault();
        event.stopPropagation();
        clearInterval(this.counterInterval);
        this.counterTarget.classList.add('hidden');
        clearInterval(this.intervalTimer);
        this.intervalTimer = null;
        this.startStreaming();
    }

    async openEditModal(event) {
        const element = event.currentTarget;
        const sampleId = element.getAttribute("data-sample-id");
        document.getElementById(`myModal-${sampleId}`).classList.remove("hidden");

        // initialize navigation buttons
        this.initializeNavigationButtons(sampleId);
    }

    initializeNavigationButtons(sampleId) {
        const currentSampleDiv = document.getElementById('sample_' + sampleId);
        if (!currentSampleDiv) {
            console.error('No current sample div found for sampleId:', sampleId);
            return;
        }

        const prevButton = document.getElementById('prev-sample-' + sampleId);
        const nextButton = document.getElementById('next-sample-' + sampleId);

        if (prevButton) {
            prevButton.disabled = !currentSampleDiv.previousElementSibling;
        }

        if (nextButton) {
            nextButton.disabled = !currentSampleDiv.nextElementSibling;
        }
    }

    navigateSample(event) {
        const direction = event.currentTarget.dataset.direction;
        const currentSampleId = event.currentTarget.dataset.sampleId;
        const currentSampleDiv = document.getElementById(`sample_${currentSampleId}`);

        if (!currentSampleDiv) {
            console.error('Current sample div not found:', `sample_${currentSampleId}`);
            return;
        }

        const siblingSampleDiv = direction === 'next' ? currentSampleDiv.nextElementSibling : currentSampleDiv.previousElementSibling;

        if (siblingSampleDiv) {
            const siblingSampleId = siblingSampleDiv.id.split('_').pop();

            // Creating a mock event object for closeEditModal
            const mockCloseEvent = {
                currentTarget: { dataset: { sampleId: currentSampleId } },
                target: { id: `myModal-${currentSampleId}` } // Mock target.id
            };
            this.closeEditModal(mockCloseEvent);

            // Open sibling modal
            const siblingLinkSelector = `a[data-sample-id='${siblingSampleId}']`;
            const siblingLink = document.querySelector(siblingLinkSelector);
            if (siblingLink) {
                siblingLink.click();
            } else {
                console.error('Sibling sample link not found:', siblingLinkSelector);
            }
        }
    }

    closeEditModal(event) {
        const modalId = `myModal-${event.currentTarget.dataset.sampleId}`;
        if (event.target.id === modalId) {
            const modal = document.getElementById(modalId);
            if (modal) {
                modal.classList.add("hidden");
            }
        }
    }

    disconnect(){
        this.renderCanvas = false;

        if (this.videoTarget.srcObject) {
            this.videoTarget.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }

        this.videoTarget.srcObject = null;
        this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
    }
}
