import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["deleteLabelBtn", "video", "canvas", "cameraError", "videoContainer", "snap", "snapBtn", "formButtonContainer", "sampleImage", "projectType", "labelsInput", "editLink", "labelName", "labelNumber", "addLabel"];
    renderCanvas = true;

    connect() {
        this.toggleSelectType();
        this.handleEnterKey = this.handleEnterKey.bind(this);
        document.addEventListener('keydown', this.handleEnterKey);
    }

    handleEnterKey(event) {
        if (event.target.getAttribute('data-project-target') === 'labelName' && event.key === 'Enter') {
            event.preventDefault();
            this.addLabel();
            // Find the close button of the add label modal and click it
            const closeButton = document.getElementById('cancel-add-label-btn')
            if (closeButton) {
                closeButton.click();
            }
        }
    }

    connectCamera() {
        this.canvasContext = this.canvasTarget.getContext('2d');
        this.renderCanvas = true;

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({ video: { width: 1280, height: 720 } })
                .then(stream => {
                    this.videoTarget.srcObject = stream;
                    this.videoTarget.onloadedmetadata = () => {

                        // If user media was previously off
                        this.videoContainerTarget.style.display = 'flex'; // Show the video container
                        this.cameraErrorTarget.style.display = 'none'; // Hide error container
                        this.snapBtnTarget.disabled = false;

                        // Dynamically set dimensions
                        const dimension = Math.min(this.videoTarget.videoWidth, this.videoTarget.videoHeight, 480);
                        this.canvasTarget.width = dimension;
                        this.canvasTarget.height = dimension;
                        this.videoTarget.width = dimension;
                        this.videoTarget.height = dimension;

                        // Stream video
                        this.videoTarget.play();
                        this.renderToCanvas();
                    };
                })
                .catch(err => {
                    this.videoContainerTarget.style.display = 'none'; // Hide the video container
                    this.cameraErrorTarget.style.display = 'flex'; // Show error container
                    this.snapBtnTarget.disabled = true;
                });
        } else {
            console.error("getUserMedia not supported in this browser.");
            this.videoContainerTarget.style.display = 'none'; // Hide the video container
            this.cameraErrorTarget.style.display = 'flex'; // Show error container
            this.snapBtnTarget.disabled = true;
        }
    }

    renderToCanvas() {
        if (this.renderCanvas) {
            const videoWidth = this.videoTarget.videoWidth;
            const videoHeight = this.videoTarget.videoHeight;
            const startX = (videoWidth - this.canvasTarget.width) / 2;
            const startY = (videoHeight - this.canvasTarget.height) / 2;

            this.canvasContext.drawImage(this.videoTarget, startX, startY, this.canvasTarget.width, this.canvasTarget.height, 0, 0, this.canvasTarget.width, this.canvasTarget.height);
            requestAnimationFrame(() => this.renderToCanvas());
        }
    }

    snap() {
        this.renderCanvas = false; // Disable live rendering

        // Capture a snapshot from the video
        const videoWidth = this.videoTarget.videoWidth;
        const videoHeight = this.videoTarget.videoHeight;
        const startX = (videoWidth - this.canvasTarget.width) / 2;
        const startY = (videoHeight - this.canvasTarget.height) / 2;

        const imageCanvas = document.createElement('canvas');
        imageCanvas.width = this.canvasTarget.width;
        imageCanvas.height = this.canvasTarget.height;
        const imageContext = imageCanvas.getContext('2d');
        imageContext.drawImage(this.videoTarget, startX, startY, this.canvasTarget.width, this.canvasTarget.height, 0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Clear the current canvas
        this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);

        // Display the captured image on the current canvas
        this.canvasContext.drawImage(imageCanvas, 0, 0);
        this.snapTarget.blur();
        this.snapTarget.style.display = 'none';
        this.formButtonContainerTarget.style.display = 'flex';

        imageCanvas.remove();
    }

    startStreaming() {
        this.renderCanvas = true; // Enable live rendering
        this.renderToCanvas();
        this.formButtonContainerTarget.style.display = 'none';
        this.snapTarget.style.display = 'flex';
    }

    cancel(event) {
        event.preventDefault()
        this.startStreaming();
    }

    attachImage() {
        // Convert canvas content to data URL
        const imageDataUrl = this.canvasTarget.toDataURL('image/jpeg');
        this.sampleImageTarget.value = imageDataUrl;

        // Find the avatar image element and set its source
        const snappedImageElement = document.getElementById('snappedImage');
        snappedImageElement.src = imageDataUrl;
        snappedImageElement.classList.remove('hidden');

        this.resetModal();
    }

    toggleSelectType() {
        const projectType = this.projectTypeTarget.value;
        const labelsInput = this.labelsInputTarget;

        if (projectType === '' || projectType === 'classifier') {
            this.addLabelTarget.disabled = true;
            this.deleteLabelBtnTarget.disabled = true;
            labelsInput.style.backgroundColor = "rgb(229 231 235)";
            this.labelsInputTarget.value = '';

        } else if (projectType === 'object_detector') {
            this.addLabelTarget.disabled = false;
            this.deleteLabelBtnTarget.disabled = false;
            labelsInput.style.backgroundColor = "white";
        }
    }

    resetModal() {
        if (this.canvasContext) {
            this.canvasContext.clearRect(0, 0, this.canvasTarget.width, this.canvasTarget.height);
        }

        this.formButtonContainerTarget.style.display = 'none';
        this.snapTarget.style.display = 'flex';
        this.disconnect();
    }

    disconnect() {
        document.removeEventListener('keydown', this.handleEnterKey);

        this.renderCanvas = false;

        if (this.videoTarget.srcObject) {
            this.videoTarget.srcObject.getTracks().forEach(track => {
                track.stop();
            });
        }

        this.videoTarget.srcObject = null;
    }

    addLabel() {
        const labelName = this.labelNameTarget.value;
        const labelNumber = this.labelNumberTarget.value;

        if (labelName && labelNumber) {
            // Format the new label - adjust this according to your needs
            const newLabel = `${labelName}: ${labelNumber}`;

            // Add the new label to the existing ones
            const currentLabels = this.labelsInputTarget.value;
            this.labelsInputTarget.value = currentLabels ? `${currentLabels}, ${newLabel}` : newLabel;

            // Clear the modal inputs
            this.labelNameTarget.value = '';
            this.labelNumberTarget.value = '1';
        }

        this.checkLabelsValueLength();
    }

    deleteLabel(event) {
        event.preventDefault();
        let labels = this.labelsInputTarget.value.split(',');
        if (labels.length > 0) {
            labels.pop();
        }
        this.labelsInputTarget.value = labels.join(',').trim();
        this.checkLabelsValueLength();
    }

    autoFocusInput() {
        setTimeout(() => this.labelNameTarget.focus(), 0);
    }

    // for edit.html.erb
    selectEditNav(event) {
        event.preventDefault();

        const activeClasses = "bg-primary-600 text-white hover:text-white dark:bg-primary-800";
        const inactiveClasses = "text-gray-700 hover:text-gray-700 hover:bg-gray-100 dark:text-gray-50 dark:hover:text-gray-200 dark:hover:bg-gray-700";

        this.editLinkTargets.forEach(link => {
            link.className = 'block no-underline p-3 px-6 ' + inactiveClasses;
        });

        event.currentTarget.className = 'block no-underline p-3 px-6 ' + activeClasses;
    }

    checkLabelsValueLength() {
        let labels = this.labelsInputTarget.value.split(',');
        if(labels.length >= 2) {
            this.addLabelTarget.disabled = true;
        }
        else {
            this.addLabelTarget.disabled = false;
        }
    }
}
