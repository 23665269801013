import { Controller } from "@hotwired/stimulus";

const CONF_THRESHOLD = 0.6;

export default class extends Controller {
    connect() {
        this.initializeWorker();
    }

    initializeWorker() {
        const model_path = document.querySelector('div[data-model_path]').getAttribute('data-model_path');
        const yolo_classes_str = document.querySelector('div[data-model_labels]').getAttribute('data-model_labels');
        this.yolo_classes = yolo_classes_str.split(',');
        this.worker = new Worker('/workers/worker.js');
        let boxes = [];
        this.interval;
        let busy = false;
        const video = document.getElementById("video");

        video.addEventListener("play", () => {
            const canvas = document.getElementById("canvas_boxes");
            const dimension = Math.min(video.videoWidth, video.videoHeight, 480);
            canvas.width = dimension;
            canvas.height = dimension;
            const context = canvas.getContext("2d");
            this.interval = setInterval(() => {
                const videoWidth = video.videoWidth;
                const videoHeight = video.videoHeight;
                const startX = (videoWidth - canvas.width) / 2;
                const startY = (videoHeight - canvas.height) / 2;
                context.drawImage(video, startX, startY, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height)
                const input = this.prepare_input(canvas);
                if (!busy) {
                    this.worker.postMessage({input, model_path});
                    busy = true;
                }
            }, 1000)
        });

        this.worker.onmessage = (event) => {
            const output = event.data;
            const canvas = document.getElementById("canvas_boxes");
            this.draw_boxes(canvas, boxes);
            boxes = this.process_output(output, canvas.width, canvas.height);
            busy = false;
        };

        video.addEventListener("pause", () => {
            clearInterval(this.interval);
        });
    }

    prepare_input(img) {
        const canvas = document.createElement("canvas");
        canvas.width = 480;
        canvas.height = 480;
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, 480, 480);
        const data = context.getImageData(0, 0, 480, 480).data;
        const red = [], green = [], blue = [];
        for (let index = 0; index < data.length; index += 4) {
            red.push(data[index] / 255);
            green.push(data[index + 1] / 255);
            blue.push(data[index + 2] / 255);
        }
        return [...red, ...green, ...blue];
    }

    process_output(output, img_width, img_height) {
        let boxes = [];
        for (let index = 0; index < 4725; index++) {
            const [class_id, prob] = [...Array(this.yolo_classes.length).keys()]
                .map(col => [col, output[4725 * (col + 4) + index]])
                .reduce((accum, item) => item[1] > accum[1] ? item : accum, [0, 0]);
            if (prob < CONF_THRESHOLD) {
                continue;
            }
            const label = this.yolo_classes[class_id];
            console.log(`LABEL: ${label}`);
            const xc = output[index];
            const yc = output[4725 + index];
            const w = output[2 * 4725 + index];
            const h = output[3 * 4725 + index];
            const x1 = (xc - w / 2) / 480 * img_width;
            const y1 = (yc - h / 2) / 480 * img_height;
            const x2 = (xc + w / 2) / 480 * img_width;
            const y2 = (yc + h / 2) / 480 * img_height;
            boxes.push([x1, y1, x2, y2, label, prob]);
        }
        boxes = boxes.sort((box1, box2) => box2[5] - box1[5])
        const result = [];
        while (boxes.length > 0) {
            result.push(boxes[0]);
            boxes = boxes.filter(box => this.iou(boxes[0], box) < 0.7 || boxes[0][4] !== box[4]);
        }
        return result;
    }

    iou(box1, box2) {
        return this.intersection(box1, box2) / this.union(box1, box2);
    }

    union(box1, box2) {
        const [box1_x1, box1_y1, box1_x2, box1_y2] = box1;
        const [box2_x1, box2_y1, box2_x2, box2_y2] = box2;
        const box1_area = (box1_x2 - box1_x1) * (box1_y2 - box1_y1)
        const box2_area = (box2_x2 - box2_x1) * (box2_y2 - box2_y1)
        return box1_area + box2_area - this.intersection(box1, box2)
    }

    intersection(box1, box2) {
        const [box1_x1, box1_y1, box1_x2, box1_y2] = box1;
        const [box2_x1, box2_y1, box2_x2, box2_y2] = box2;
        const x1 = Math.max(box1_x1, box2_x1);
        const y1 = Math.max(box1_y1, box2_y1);
        const x2 = Math.min(box1_x2, box2_x2);
        const y2 = Math.min(box1_y2, box2_y2);
        return (x2 - x1) * (y2 - y1)
    }

    draw_boxes(canvas, boxes) {
        const ctx = canvas.getContext("2d");
        ctx.strokeStyle = "#00FF00";
        ctx.lineWidth = 3;
        ctx.font = "18px serif";
        console.log("boxes", boxes);
        boxes.forEach(([x1, y1, x2, y2, label]) => {
            ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);
            ctx.fillStyle = "#00ff00";
            const width = ctx.measureText(label).width;
            ctx.fillRect(x1, y1, width + 10, 25);
            ctx.fillStyle = "#000000";
            ctx.fillText(label, x1, y1 + 18);
        });
    }

    disconnect() {
        if (this.worker) {
            clearInterval(this.interval);
            this.worker.terminate();
            this.worker = null;
            console.log("Worker terminated");
        }
    }
}