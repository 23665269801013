import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["usageButton"];
    static values = { open: Boolean };

    connect() {
        this.checkSlideoverLoaded();
    }

    checkSlideoverLoaded() {
        const checkInterval = 100; // milliseconds
        const maxAttempts = 50;
        let attempts = 0;

        const interval = setInterval(() => {
            attempts++;
            const slideoverLoaded = this.isSlideoverLoaded();

            if (slideoverLoaded || attempts >= maxAttempts) {
                clearInterval(interval);
                if (slideoverLoaded) {
                    this.openSlideover();
                }
            }
        }, checkInterval);
    }

    isSlideoverLoaded() {
        return document.getElementById('slideover') !== null;
    }

    openSlideover() {
        const usageButton = document.getElementById('usage-btn');
        if (usageButton && this.openValue) {
            usageButton.dispatchEvent(new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            }));
        }
    }
}
