import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static values = { onUrl: String, offUrl: String }

    connect() {
        this.initializeWorker();
        document.addEventListener('keydown', this.handleKeydown.bind(this))
    }

    initializeWorker() {
        this.model_path = document.querySelector('div[data-model_path]').getAttribute('data-model_path');
        this.conf_thres = document.querySelector('div[data-model_path]').getAttribute('data-conf_thres');
        this.iou_thres = document.querySelector('div[data-model_path]').getAttribute('data-iou_thres');
        const yolo_classes_str = document.querySelector('div[data-model_labels]').getAttribute('data-model_labels');
        this.yolo_classes = yolo_classes_str.split(',');
        this.worker = new Worker("/workers/worker.js");
        this.boxes = [];
        this.busy = false;

        this.canvas = document.getElementById("canvas");

        const sampleImage = document.getElementById("sampleImage");
        if (sampleImage.complete && sampleImage.naturalHeight !== 0) {
            this.processImage(sampleImage);
        } else {
            sampleImage.onload = () => {
                this.processImage(sampleImage);
            };
        }

        this.worker.onmessage = (event) => {
            const output = event.data;
            this.boxes =  this.process_output(output, 640, 640);
            this.draw_boxes(this.canvas, this.boxes); // Draw boxes immediately

            this.busy = false;

            const spinner = document.getElementById("spinner");
            if (this.boxes.length > 0) {
                spinner.style.display = 'none';
            }
            else {
                spinner.innerHTML = 'No objects found';
            }
        };
    }

    processImage(img) {
        if (this.busy) {
            return; // Skip if busy
        }
        const input = this.prepare_input(img);
        this.worker.postMessage({ input, model_path: this.model_path });
        this.busy = true;
    }

    terminateWorker() {
        if (this.worker) {
            this.worker.terminate();
            this.worker = null;
        }
    }

    prepare_input(img) {
        const canvas = document.createElement("canvas");
        canvas.width = 640;
        canvas.height = 640;
        const context = canvas.getContext("2d");
        context.drawImage(img, 0, 0, 640, 640);
        const data = context.getImageData(0,0,640,640).data;
        const red = [], green = [], blue = [];
        for (let index=0;index<data.length;index+=4) {
            red.push(data[index]/255);
            green.push(data[index+1]/255);
            blue.push(data[index+2]/255);
        }
        return [...red, ...green, ...blue];
    }

    process_output(output, img_width, img_height) {
        let boxes = [];
        for (let index = 0; index < 8400; index++) {
            const [class_id, prob] = [...Array(this.yolo_classes.length).keys()]
                .map(col => [col, output[8400 * (col + 4) + index]])
                .reduce((accum, item) => item[1] > accum[1] ? item : accum, [0, 0]);
            if (prob < this.conf_thres) {
                continue;
            }
            const label = this.yolo_classes[class_id];
            const xc = output[index];
            const yc = output[8400 + index];
            const w = output[2 * 8400 + index];
            const h = output[3 * 8400 + index];
            const x1 = (xc - w / 2) / 640 * img_width;
            const y1 = (yc - h / 2) / 640 * img_height;
            const x2 = (xc + w / 2) / 640 * img_width;
            const y2 = (yc + h / 2) / 640 * img_height;
            boxes.push([x1, y1, x2, y2, label, prob]);
        }
        boxes = boxes.sort((box1, box2) => box2[5] - box1[5])
        const result = [];
        while (boxes.length > 0) {
            result.push(boxes[0]);
            boxes = boxes.filter(box => this.iou(boxes[0], box) < this.iou_thres || boxes[0][4] !== box[4]
            );
        }
        return result;
    }

    iou(box1, box2) {
        return this.intersection(box1, box2) / this.union(box1, box2);
    }

    union(box1, box2) {
        const [box1_x1, box1_y1, box1_x2, box1_y2] = box1;
        const [box2_x1, box2_y1, box2_x2, box2_y2] = box2;
        const box1_area = (box1_x2 - box1_x1) * (box1_y2 - box1_y1)
        const box2_area = (box2_x2 - box2_x1) * (box2_y2 - box2_y1)
        return box1_area + box2_area - this.intersection(box1, box2)
    }

    intersection(box1, box2) {
        const [box1_x1, box1_y1, box1_x2, box1_y2] = box1;
        const [box2_x1, box2_y1, box2_x2, box2_y2] = box2;
        const x1 = Math.max(box1_x1, box2_x1);
        const y1 = Math.max(box1_y1, box2_y1);
        const x2 = Math.min(box1_x2, box2_x2);
        const y2 = Math.min(box1_y2, box2_y2);
        return (x2 - x1) * (y2 - y1)
    }

    draw_boxes(canvas, boxes) {
        const ctx = canvas.getContext("2d");
        ctx.strokeStyle = "#00FF00";
        ctx.lineWidth = 2;
        ctx.font = "600 14px Nunito";

        boxes.forEach(([x1, y1, x2, y2, label, score]) => {
            // Draw the dashed rectangle
            ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

            // Calculate label width and height
            const label_score = `${label} ${score.toFixed(2)}`
            const labelWidth = ctx.measureText(label_score).width + 10;
            const labelHeight = 20; // Adjusted label height based on new font size
            const textPadding = 5; // Padding before the first character

            // Determine label position
            let labelX = x1;
            let labelY = y1 - labelHeight - 3; // Position above the bounding box

            // Adjust label position if it goes off the top of the canvas
            if (labelY < 0) {
                labelY = y2 + 3; // Position below the bounding box
            }

            // Draw label background
            ctx.fillStyle = "white";
            ctx.fillRect(labelX, labelY, labelWidth, labelHeight);

            // Draw label text with padding
            ctx.fillStyle = "#000000";
            ctx.fillText(label_score, labelX + textPadding, labelY + labelHeight - 4);  // Adjusted text position
        });
    }

    handleKeydown(event) {
        if (event.keyCode === 39) { // Right arrow key
            this.nextPage();
        } else if (event.keyCode === 37) { // Left arrow key
            this.prevPage();
        }
    }

    nextPage() {
        // Logic to navigate to the next page
        const nextButton = document.getElementById('nextBtn');
        if (nextButton) {
            nextButton.click();
        }
    }

    prevPage() {
        // Logic to navigate to the previous page
        const prevButton = document.getElementById('prevBtn');
        if (prevButton) {
            prevButton.click();
        }
    }

    toggleSwitchOff() {
        const switchButton = this.element.querySelector('button[role="switch"]');
        switchButton.setAttribute('aria-checked', 'false');
        const span = switchButton.querySelector('span');
        switchButton.classList.replace('bg-indigo-600', 'bg-gray-200');
        span.classList.replace('translate-x-5', 'translate-x-0');
        document.getElementById('show-labels-link').click();
    }

    disconnect() {
        document.removeEventListener('keydown', this.handleKeydown.bind(this))
        clearInterval(this.interval);
        this.worker.terminate();
        this.worker = null;
    }
}