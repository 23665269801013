import { Controller } from "@hotwired/stimulus"

let anno = null;

export default class extends Controller {
    static values = { annotations: Array, onUrl: String, offUrl: String };
    static targets = ["annotationsInput"];

    async connect() {
        document.addEventListener('keydown', this.handleKeydown.bind(this))
        if (!anno) {
            await this.initializeAnnotorious();
        }
       anno.setAnnotations(this.annotationsValue);
    }

    initializeAnnotorious() {
        anno = Annotorious.init({
            image: document.getElementById("sampleImage"),
            readOnly: true,
            disableEditor: true,
            formatter: Annotorious.ShapeLabelsFormatter()
        });

        anno.on('createAnnotation', (annotation) => {
            this.updateFormValue();
        });

        anno.on('updateAnnotation', (annotation, previous) => {
            this.updateFormValue();
        });

        anno.on('deleteAnnotation', (annotation) => {
            this.updateFormValue();
        });
    }

    updateFormValue() {
        const annotations = anno.getAnnotations();
        const annotationsJson = JSON.stringify(annotations);
        const inputElement = document.getElementById("annotationsInput");
        if (inputElement) {
            inputElement.value = annotationsJson;
        }
    }

    handleKeydown(event) {
        if (event.keyCode === 39) { // Right arrow key
            this.nextPage();
        } else if (event.keyCode === 37) { // Left arrow key
            this.prevPage();
        }
    }

    nextPage() {
        // Logic to navigate to the next page
        const nextButton = document.getElementById('nextBtn');
        if (nextButton) {
            nextButton.click();
        }
    }

    prevPage() {
        // Logic to navigate to the previous page
        const prevButton = document.getElementById('prevBtn');
        if (prevButton) {
            prevButton.click();
        }
    }

    toggleSwitchOn() {
        const switchButton = this.element.querySelector('button[role="switch"]');
        switchButton.setAttribute('aria-checked', 'true');
        const span = switchButton.querySelector('span');
        switchButton.classList.replace('bg-gray-200', 'bg-indigo-600');
        span.classList.replace('translate-x-0', 'translate-x-5');
        document.getElementById('predict-link').click();
    }

    disconnect() {
        document.removeEventListener('keydown', this.handleKeydown.bind(this))
        if (anno) {
            anno.destroy();
            anno = null;
        }
    }
}