// manually lazy loading image for turbo frames
document.addEventListener('turbo:frame-load', function() {
    // Find all images with 'data-src' attribute within the updated Turbo Frame
    const lazyImages = document.querySelectorAll('img[data-src]');

    lazyImages.forEach(img => {
        // Set the 'src' attribute to the value of 'data-src'
        img.setAttribute('src', img.getAttribute('data-src'));

        // Optionally, remove the 'data-src' attribute after setting 'src'
        img.removeAttribute('data-src');
    });
});