import { Controller } from "@hotwired/stimulus";

const CONF_THRESHOLD = 0.25;
const IOU_THRESHOLD = 0.45;

export default class extends Controller {
    static targets = ['link'];

    connect() {
    }

    stopPropagation(event) {
        event.stopPropagation();
    }

    snap() {
        this.retakeButton = this.targets.find("retakeButton");
        this.retakeButton.disabled = true;
        this.spinner = document.getElementById("spinner");
        this.spinner.style.display = "flex";
        if (!this.worker) {
            this.initializeWorker();
        }
        const input = this.prepare_input();
        this.model_path = document.querySelector('div[data-model_path]').getAttribute('data-model_path');
        this.conf_thres = document.querySelector('div[data-model_path]').getAttribute('data-conf_thres');
        this.iou_thres = document.querySelector('div[data-model_path]').getAttribute('data-iou_thres');
        this.worker.postMessage({input, model_path: this.model_path});
    }

    retake() {
        this.spinner.style.display = "none";
        this.spinner.innerHTML = `
          <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-indigo-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
          Loading inference
        `;
        const canvas = document.getElementById("canvas_boxes");
        if (canvas) {
            const ctx = canvas.getContext("2d");
            ctx.clearRect(0, 0, canvas.width, canvas.height);
        }
        this.boxes = [];
        this.busy = false;
    }

    initializeWorker() {
        const yolo_classes_str = document.querySelector('div[data-model_labels]').getAttribute('data-model_labels');
        this.yolo_classes = yolo_classes_str.split(',')
        this.worker = new Worker("/workers/worker.js");
        this.boxes = [];
        this.busy = false;
        this.canvas = document.getElementById("canvas_boxes");

        this.worker.onmessage = (event) => {
            const output = event.data;
            this.boxes =  this.process_output(output, 640, 640);
            this.draw_boxes(this.canvas, this.boxes);
            this.busy = false;
            this.retakeButton.disabled = false;
            if (this.boxes.length > 0) {
                this.spinner.style.display = 'none';
            }
            if (this.boxes.length === 0) {
                this.spinner.innerHTML = `No boxes found`;
            }
        };
    }

    terminateWorker() {
        if (this.worker) {
            this.worker.terminate();
            this.worker = null;
        }
    }

    prepare_input() {
        const canvas = document.getElementById("canvas")
        const context = canvas.getContext("2d");
        const data = context.getImageData(0, 0, 640, 640).data;
        const red = [], green = [], blue = [];
        for (let index = 0; index < data.length; index += 4) {
            red.push(data[index] / 255);
            green.push(data[index + 1] / 255);
            blue.push(data[index + 2] / 255);
        }
        return [...red, ...green, ...blue];
    }

    process_output(output, img_width, img_height) {
        let boxes = [];
        for (let index = 0; index < 8400; index++) {
            const [class_id, prob] = [...Array(this.yolo_classes.length).keys()]
                .map(col => [col, output[8400 * (col + 4) + index]])
                .reduce((accum, item) => item[1] > accum[1] ? item : accum, [0, 0]);
            if (prob < this.conf_thres) {
                continue;
            }
            const label = this.yolo_classes[class_id];
            const xc = output[index];
            const yc = output[8400 + index];
            const w = output[2 * 8400 + index];
            const h = output[3 * 8400 + index];
            const x1 = (xc - w / 2) / 640 * img_width;
            const y1 = (yc - h / 2) / 640 * img_height;
            const x2 = (xc + w / 2) / 640 * img_width;
            const y2 = (yc + h / 2) / 640 * img_height;
            boxes.push([x1, y1, x2, y2, label, prob]);
        }
        boxes = boxes.sort((box1, box2) => box2[5] - box1[5])
        const result = [];
        while (boxes.length > 0) {
            result.push(boxes[0]);
            boxes = boxes.filter(box => this.iou(boxes[0], box) < this.iou_thres || boxes[0][4] !== box[4]);
        }
        return result;
    }

    iou(box1, box2) {
        return this.intersection(box1, box2) / this.union(box1, box2);
    }

    union(box1, box2) {
        const [box1_x1, box1_y1, box1_x2, box1_y2] = box1;
        const [box2_x1, box2_y1, box2_x2, box2_y2] = box2;
        const box1_area = (box1_x2 - box1_x1) * (box1_y2 - box1_y1)
        const box2_area = (box2_x2 - box2_x1) * (box2_y2 - box2_y1)
        return box1_area + box2_area - this.intersection(box1, box2)
    }

    intersection(box1, box2) {
        const [box1_x1, box1_y1, box1_x2, box1_y2] = box1;
        const [box2_x1, box2_y1, box2_x2, box2_y2] = box2;
        const x1 = Math.max(box1_x1, box2_x1);
        const y1 = Math.max(box1_y1, box2_y1);
        const x2 = Math.min(box1_x2, box2_x2);
        const y2 = Math.min(box1_y2, box2_y2);
        return (x2 - x1) * (y2 - y1)
    }

    draw_boxes(canvas, boxes) {
        const ctx = canvas.getContext("2d");
        ctx.strokeStyle = "#00FF00";
        ctx.lineWidth = 2;
        ctx.font = "600 14px Nunito";

        boxes.forEach(([x1, y1, x2, y2, label, score]) => {
            // Draw the dashed rectangle
            ctx.strokeRect(x1, y1, x2 - x1, y2 - y1);

            // Calculate label width and height
            const label_score = `${label} ${score.toFixed(2)}`
            const labelWidth = ctx.measureText(label_score).width + 10;
            const labelHeight = 20; // Adjusted label height based on new font size
            const textPadding = 5; // Padding before the first character

            // Determine label position
            let labelX = x1;
            let labelY = y1 - labelHeight - 3; // Position above the bounding box

            // Adjust label position if it goes off the top of the canvas
            if (labelY < 0) {
                labelY = y2 + 3; // Position below the bounding box
            }

            // Draw label background
            ctx.fillStyle = "white";
            ctx.fillRect(labelX, labelY, labelWidth, labelHeight);

            // Draw label text with padding
            ctx.fillStyle = "#000000";
            ctx.fillText(label_score, labelX + textPadding, labelY + labelHeight - 4);  // Adjusted text position
        });
    }

    disconnect() {
        this.worker.terminate();
        this.worker = null;
    }
}