import { Controller } from "@hotwired/stimulus"

let photoTaken = false;
let canAnnotate = false;
let anno = null;
let shouldDrawLines = true;
let labelSelected = false;
let currentAnnotation = null;

export default class extends Controller {
    static targets = ["labelButton", "sampleAnnotations", "usageDetails", "editModal", "annotationsInput"]
    static values = {
        selected: Object,
        label: String,
        lastSampleAnnotations: Array,
        labels: Array,
        annotations: Array
    }

    connect() {
        this.initialAnnotations = this.lastSampleAnnotationsValue;
    }

    loadAnnotations(annotations) {
        if (annotations.length > 0 && anno) {
            annotations.forEach(annotation => {
                anno.addAnnotation(annotation);
            });
        }
    }

    setupLineDrawing() {
        const container = document.getElementById('videoContainer');
        const crosshairCanvas = document.getElementById('canvas_cross_hairs');
        if (container && crosshairCanvas) {
            crosshairCanvas.width = container.offsetWidth;
            crosshairCanvas.height = container.offsetHeight;
            container.addEventListener('mousemove', this.drawLines.bind(this, crosshairCanvas));
            container.addEventListener('mouseleave', this.clearLines.bind(this, crosshairCanvas));
        }
    }

    drawLines(crosshairCanvas, event) {
        if (!shouldDrawLines || !canAnnotate) {
            return;
        }

        const ctx = crosshairCanvas.getContext('2d');
        const rect = crosshairCanvas.getBoundingClientRect();
        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        this.clearLines(crosshairCanvas);
        this.createLine(ctx, x, 0, x, crosshairCanvas.height);
        this.createLine(ctx, 0, y, crosshairCanvas.width, y);
    }

    createLine(ctx, x1, y1, x2, y2) {
        ctx.beginPath();
        ctx.moveTo(x1, y1);
        ctx.lineTo(x2, y2);
        ctx.strokeStyle = 'rgba(11, 218, 81)';
        ctx.lineWidth = 2;
        ctx.stroke();
    }

    clearLines(crosshairCanvas) {
        if (crosshairCanvas) {
            const ctx = crosshairCanvas.getContext('2d');
            ctx.clearRect(0, 0, crosshairCanvas.width, crosshairCanvas.height);
        }
    }

    updateCrosshairVisibility() {
        const crosshairCanvas = document.getElementById('canvas_cross_hairs');
        if (!canAnnotate && crosshairCanvas) {
            this.clearLines(crosshairCanvas);
        }
    }

    async snap() {
        await this.initializeAnnotorious('canvas');

        photoTaken = true;
        canAnnotate = true;

        if (this.lastSampleAnnotationsValue.length > 0) {
            this.loadAnnotations(this.lastSampleAnnotationsValue);
        }

        anno.off('createSelection');
        this.setupLineDrawing();

        if (this.labelsValue.length === 1) {
            this.applySingleLabelAnnotation(this.labelsValue[0]);
        } else {
            anno.on('createSelection', async (selection) => {
                currentAnnotation = selection;
                document.getElementById('label-modal').click();
            });
        }
    }

    applySingleLabelAnnotation(label) {
        anno.on('createSelection', async (selection) => {
            currentAnnotation = selection;
            currentAnnotation.body = [{ type: 'TextualBody', purpose: 'tagging', value: label }];
            await anno.updateSelected(currentAnnotation, true);
        });
    }

    async handleLabelClick(event) {
        if (!currentAnnotation) {
            console.error("No annotation selected.");
            return;
        }

        this.selectButton(event.currentTarget);
        const labelValue = event.currentTarget.getAttribute('data-annotate-label-value').trim();

        currentAnnotation.body = [{type: 'TextualBody', purpose: 'tagging', value: labelValue}];
        await anno.updateSelected(currentAnnotation, true);
    }

    selectButton(selectedButton) {
        this.labelButtonTargets.forEach((button) => {
            button.classList.remove('bg-gray-300');
        });
        selectedButton.classList.add('bg-gray-300');
    }

    async initializeAnnotorious(targetId) {
        anno = Annotorious.init({
            image: document.getElementById(targetId),
            disableEditor: true,
            formatter: Annotorious.ShapeLabelsFormatter(),
        });

        this.addDeleteHandler();
        this.addMoveAnnoUpdateHandler();
    }

    addMoveAnnoUpdateHandler() {
        anno.on('mouseLeaveAnnotation', function(annotation, element) {
            const selection = anno.getSelected();
            if (selection) {
                anno.updateSelected(selection, true);
            }
        });
    }

    addDeleteHandler() {
        anno.on('selectAnnotation', (annotation) => {
            this.selectedValue = annotation;
        });

        this.handleKeyDown = (event) => {
            if (event.key === "Backspace" && this.selectedValue) {
                anno.removeAnnotation(this.selectedValue.id);
                this.updateSampleAnnotations();
                this.selectedValue = null;
            }
        };

        document.addEventListener('keydown', this.handleKeyDown);
    }

    updateSampleAnnotations() {
        const currentAnnotations = anno.getAnnotations();
        this.sampleAnnotationsTarget.value = JSON.stringify(currentAnnotations);
    }

    cancel() {
        this.initialAnnotations.forEach(annotation => {
            anno.addAnnotation(annotation);
        });
        this.resetState();

    }

    submit() {
        this.sampleAnnotationsTarget.value = JSON.stringify(anno.getAnnotations());
        this.lastSampleAnnotationsValue = anno.getAnnotations();
        this.resetState();
    }

    resetState() {
        photoTaken = false;
        canAnnotate = false;
        this.updateCrosshairVisibility();
        this.destroyAnno();
    }

    showUsage(event) {
        this.usageDetailsTarget.classList.toggle('hidden');
    }

    async openEditModal(event) {
        this.destroyAnno();
        const element = event.currentTarget;
        const sampleId = element.getAttribute("data-annotate-id");
        const annotationsValue = element.getAttribute("data-annotate-annotations-value");
        document.getElementById(`myModal-${sampleId}`).classList.remove("hidden");
        await this.initializeEditSampleAnnotorious(`editSample-${sampleId}`);
        anno.setAnnotations(JSON.parse(annotationsValue));

        // initialize navigation buttons
        this.initializeNavigationButtons(sampleId);
    }

    closeEditModal(event) {
        const modalId = `myModal-${event.currentTarget.dataset.sampleId}`;
        if (event.target.id === modalId) {
            const modal = document.getElementById(modalId);
            if (modal) {
                modal.classList.add("hidden");
                this.resetState();
            }
        }
    }

    async initializeEditSampleAnnotorious(targetId) {
        anno = Annotorious.init({
            image: document.getElementById(targetId),
            disableEditor: true,
            formatter: Annotorious.ShapeLabelsFormatter(),
        });

        anno.off('createSelection');
        this.setupLineDrawing();

        if (this.labelsValue.length === 1) {
            this.applySingleLabelAnnotation(this.labelsValue[0]);
        } else {
            anno.on('createSelection', async (selection) => {
                currentAnnotation = selection;
                document.getElementById('label-modal').click();
            });
        }

        this.addEditSampleDeleteHandler();
        this.addEditSampleMoveAnnoUpdateHandler();
    }

    addEditSampleMoveAnnoUpdateHandler() {
        anno.on('mouseLeaveAnnotation', function(annotation, element) {
            const selection = anno.getSelected();
            if (selection) {
                anno.updateSelected(selection, true);
            }
        });
    }

    addEditSampleDeleteHandler() {
        anno.on('selectAnnotation', (annotation) => {
            this.selectedValue = annotation;
        });

        this.handleKeyDown = (event) => {
            if (event.key === "Backspace" && this.selectedValue) {
                anno.removeAnnotation(this.selectedValue.id);
                this.selectedValue = null;
            }
        };

        document.addEventListener('keydown', this.handleKeyDown);
    }

    submitEditSample(event) {
        const element = event.currentTarget;
        const sampleId = element.getAttribute("data-sample-id");
        const annotations = anno.getAnnotations();
        const annotationsJson = JSON.stringify(annotations);
        const inputElement = document.getElementById(`annotationsInput-${sampleId}`);
        if (inputElement) {
            inputElement.value = annotationsJson;
        }
        this.resetState();
    }

    destroyAnno() {
        if (anno) {
            anno.destroy();
            anno = null;
        }
    }

    initializeNavigationButtons(sampleId) {
        const currentSampleDiv = document.getElementById('sample_' + sampleId);
        if (!currentSampleDiv) {
            console.error('No current sample div found for sampleId:', sampleId);
            return;
        }

        const prevButton = document.getElementById('prev-sample-' + sampleId);
        const nextButton = document.getElementById('next-sample-' + sampleId);

        if (prevButton) {
            prevButton.disabled = !currentSampleDiv.previousElementSibling;
        }

        if (nextButton) {
            nextButton.disabled = !currentSampleDiv.nextElementSibling;
        }
    }

    navigateSample(event) {
        const direction = event.currentTarget.dataset.direction;
        const currentSampleId = event.currentTarget.dataset.sampleId;
        const currentSampleDiv = document.getElementById(`sample_${currentSampleId}`);

        if (!currentSampleDiv) {
            console.error('Current sample div not found:', `sample_${currentSampleId}`);
            return;
        }

        const siblingSampleDiv = direction === 'next' ? currentSampleDiv.nextElementSibling : currentSampleDiv.previousElementSibling;

        if (siblingSampleDiv) {
            const siblingSampleId = siblingSampleDiv.id.split('_').pop();

            // Creating a mock event object for closeEditModal
            const mockCloseEvent = {
                currentTarget: { dataset: { sampleId: currentSampleId } },
                target: { id: `myModal-${currentSampleId}` } // Mock target.id
            };
            this.closeEditModal(mockCloseEvent);

            // Open sibling modal
            const siblingLinkSelector = `a[data-annotate-id='${siblingSampleId}']`;
            const siblingLink = document.querySelector(siblingLinkSelector);
            if (siblingLink) {
                siblingLink.click();
            } else {
                console.error('Sibling sample link not found:', siblingLinkSelector);
            }
        }
    }

    startTraining() {
        document.getElementById('loading-screen').style.display = 'flex';
    }

    disconnect() {
        const container = document.getElementById('videoContainer');
        if (container) {
            container.removeEventListener('mousemove', this.drawLines);
            container.removeEventListener('mouseleave', this.clearLines);
        }
        this.destroyAnno();
    }
}