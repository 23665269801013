import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    toggleRow(event) {
        const detailRowId = "details-" + this.element.id;
        const detailRow = document.getElementById(detailRowId);
        const icon = event.currentTarget.querySelector('.fa-solid');

        if (detailRow) {
            detailRow.classList.toggle("hidden");

            if (detailRow.classList.contains("hidden")) {
                icon.classList.remove("fa-caret-down");
                icon.classList.add("fa-caret-right");
            } else {
                icon.classList.remove("fa-caret-right");
                icon.classList.add("fa-caret-down");
            }
        }
    }
}

