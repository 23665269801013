import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    toggleRow(event) {
        const button = event.currentTarget;
        const toggleRow = button.closest('tr'); // Reference to the current row
        const edgeSessionId = button.closest('[data-edge-session-id]').dataset.edgeSessionId;
        const allRows = document.querySelectorAll(`tr[data-edge-session-id="${edgeSessionId}"]`);
        let isExpanded = false;

        allRows.forEach((row, index) => {
            if (index > 0) { // Skip the first row
                row.classList.toggle('hidden');
                isExpanded = isExpanded || !row.classList.contains('hidden');
            }
        });

        const icon = button.querySelector('.fa-solid');
        if (icon) {
            if (isExpanded) {
                toggleRow.classList.add('bg-gray-100');
                icon.classList.remove('fa-caret-right');
                icon.classList.add('fa-caret-down');
            } else {
                toggleRow.classList.remove('bg-gray-100');
                icon.classList.remove('fa-caret-down');
                icon.classList.add('fa-caret-right');
            }
        }
    }
}
