import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = [
        "gettingStarted",
        "project",
        "model",
        "deploy",
        "gettingStartedBtn",
        "showProjectBtn",
        "showModelBtn",
        "showDeployBtn"
    ]

    connect() {
        this.showGettingStarted();
    }

    showGettingStarted() {
        this.hideAllSections();
        this.gettingStartedTarget.classList.remove("hidden");
        this.updateButtonStyles(this.gettingStartedBtnTarget);
    }

    showProject() {
        this.hideAllSections();
        this.projectTarget.classList.remove("hidden");
        this.updateButtonStyles(this.showProjectBtnTarget);
    }

    showModel() {
        this.hideAllSections();
        this.modelTarget.classList.remove("hidden");
        this.updateButtonStyles(this.showModelBtnTarget);
    }

    showDeploy() {
        this.hideAllSections();
        this.deployTarget.classList.remove("hidden");
        this.updateButtonStyles(this.showDeployBtnTarget);
    }

    hideAllSections() {
        this.gettingStartedTarget.classList.add("hidden");
        this.projectTarget.classList.add("hidden");
        this.modelTarget.classList.add("hidden");
        this.deployTarget.classList.add("hidden");
    }

    updateButtonStyles(activeButtonElement) {
        [this.gettingStartedBtnTarget, this.showProjectBtnTarget, this.showModelBtnTarget, this.showDeployBtnTarget].forEach(button => {
            button.classList.add("btn-primary", "btn-outline");
        });

        activeButtonElement.classList.remove("btn-outline");
    }
}
